<template>
  <RightBar ref="myRightbar" :title="title" :loading="loading">
    <template v-if="(typeof data.campaign !== 'undefined')">
      <v-card flat class="rounded-0">
        <v-img
          :src="data.campaign.img_src"
          class="white--text align-end"
          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
          height="200px"
        >
          <v-card-title style="color:#fff !important" v-text="data.campaign.description"></v-card-title>
        </v-img>
        <v-list two-line subheader dense>
          <v-subheader>General</v-subheader>
          <v-list-item dense style="min-height: 50px">
            <v-list-item-content>
              <v-list-item-title class="lbl">No.</v-list-item-title>
              <v-list-item-subtitle class="vl">{{ data.campaign.no_ ? data.campaign.no_ : '-' }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item dense style="min-height: 50px">
            <v-list-item-content>
              <v-list-item-title class="lbl">Description</v-list-item-title>
              <v-list-item-subtitle class="vl">{{ data.campaign.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item dense style="min-height: 50px">
            <v-list-item-content>
              <v-list-item-title class="lbl">Location</v-list-item-title>
              <v-list-item-subtitle class="vl">{{ data.campaign.city }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </template>
  </RightBar>
</template>

<script>
import RightBar from '@/components/RightBar.vue'

export default {
  components: {
    RightBar,
  },

  data: () => ({
    title: 'Campaign Info',
    subtitle: '',
    data: {},
    loading: false,
  }),

  methods: {
    getData(no_) {
      this.data = {}
      this.loading = true
      this.$refs.myRightbar.show()
      var params = {
        no_: no_,
      }
      this.$axios
        .get('campaign/info', { params: params, cacheConfig: true, })
        .then(res => {
          this.loading = false
          if (res.status) {
            var resData = res.data
            if (resData.status == 'success') {
              this.data = resData.data
            } else {
              this.showAlert(resData.status, resData.message);
              this.$refs.myRightbar.hide()
            }
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          // console.log(error)
          this.$awn.alert('Internal Server Error')
          this.loading = false
        })
    },
    show() {
      this.$refs.myRightbar.show()
    },
    hide() {
      this.$refs.myRightbar.hide()
    },
  },
}
</script>

<style>
.lbl {
  color: #bdbdbd !important;
}
.vl {
  color: #616161 !important;
}
</style>