var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-card',{attrs:{"loading":_vm.loadingCard}},[(_vm.loadingCard)?_c('v-skeleton-loader',_vm._b({attrs:{"type":"tab, table"}},'v-skeleton-loader',{
            boilerplate: true,
            elevation: 2,
          },false)):_vm._e(),_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Your Orders")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.getData}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-tabs',{staticClass:"tab-index",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.tabs),function(item){return [(item.data.length > 0)?_c('v-tab',{key:item.name},[_c('v-badge',{attrs:{"color":"success","content":item.data.length,"inline":""}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.name)+" ")],1)],1):_vm._e()]})],2),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.tabs),function(item,i){return [(item.data.length > 0)?_c('v-tab-item',{key:i,staticClass:"mt-2"},[_c('v-card-title',[_vm._v(" "+_vm._s(item.name)+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(item.search),callback:function ($$v) {_vm.$set(item, "search", $$v)},expression:"item.search"}})],1),_c('v-data-table',{attrs:{"loading":item.loading,"loading-text":"Loading... Please wait","headers":item.columns,"items":item.data,"search":item.search,"dense":""},scopedSlots:_vm._u([{key:"item.po_no_",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"250px"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$refs.refPOInfo.getData(item.po_no_)}}},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.po_no_)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.po_no_))])])]}},{key:"item.contract_no_",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"250px"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$refs.refContractInfo.getData(item.contract_no_)}}},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.contract_no_)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.contract_no_))])])]}},{key:"item.customer_name",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"250px"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.customer_name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.customer_name))])])]}},{key:"item.campaign_description",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"250px"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$refs.refCampaignInfo.getData(item.campaign_no_)}}},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.campaign_description)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.campaign_description))])])]}},{key:"item.line_order_amount",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberFormat(item.line_order_amount, 2))+" ")]}}],null,true)})],1):_vm._e()]})],2)],1)],1)],1),_c('ContractInfo',{ref:"refContractInfo"}),_c('POInfo',{ref:"refPOInfo"}),_c('CampaignInfo',{ref:"refCampaignInfo"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }