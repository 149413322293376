<template>
  <div>
    <v-row>
      <v-col cols="12" md="6" lg="8">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card dark>
              <v-img :src="require('@/assets/images/bg1.jpg')">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
                <v-card-text class="py-2 text-center">
                  <v-icon large color="primary">mdi-shopping</v-icon>
                  <h1>Project Orders</h1>
                  <p>This page show confirmed orders.</p>
                </v-card-text>
              </v-img>
              <v-fade-transition>
                <v-overlay v-if="hover" absolute color="#036358">
                  <a href="#yourOrder" color="white">See more</a>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </template>
        </v-hover>
      </v-col>
      <v-col cols="12" md="3" lg="2">
        <statistics-card-vertical class="fill-height" :loading="showLoadingComponent" change color="success" icon="mdi-book" :statistics="numberFormat(totalContract)" stat-title="Your Contracts" subtitle="Number of Confirmed Contracts"></statistics-card-vertical>
      </v-col>
      <v-col cols="12" md="3" lg="2">
        <statistics-card-vertical class="fill-height" :loading="showLoadingComponent" change color="primary" icon="mdi-shopping" :statistics="numberFormat(totalPO)" stat-title="Your Orders" subtitle="Number of confirmed PO"></statistics-card-vertical>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <your-orders ref="compYourOrder" class="my-2" id="yourOrder"></your-orders>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import OrdersByType from '@/components/orders/OrdersByType.vue'
import YourOrders from '@/components/orders/YourOrders.vue'
import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'

export default {
  components: {
    // OrdersByType,
    YourOrders,
    StatisticsCardVertical,
  },
  data: () => ({
    totalContract: 0,
    totalPO: 0,
    showLoadingComponent: null,
  }),
  methods: {
    getTotal() {
      this.showLoadingComponent = true

      this.$axios
        .get('orders/customerorderstotal', { cacheConfig: true, })
        .then(res => {
          if (res.status) {
            var resData = res.data
            if (resData.status == 'success') {
              this.totalContract = resData.data.total_contract
              this.totalPO = resData.data.total_po
            } else {
              this.showAlert(resData.status, resData.message)
            }
          }
          this.showLoadingComponent = false
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          // console.log(error)
          this.$awn.alert('Internal Server Error')
          this.showLoadingComponent = false
        })
    },
  },
  mounted() {
    this.$store.state.showBackButton = false

    this.setTitle('Your Orders')

    this.getTotal()
  },
}
</script>