<template>
  <div>
    <v-row>
      <v-col cols="12" lg="12">
        <v-card :loading="loadingCard">
          <v-skeleton-loader
            v-bind="{
              boilerplate: true,
              elevation: 2,
            }"
            v-if="loadingCard"
            type="tab, table"
          >
          </v-skeleton-loader>

          
          <!-- <v-card-title>Invoice</v-card-title> -->
          <v-toolbar>
            <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
            <v-toolbar-title>Your Orders</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="getData">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-toolbar>

          <!-- <v-card-title>Order Confirmation</v-card-title> -->
          <v-tabs v-model="tab" class="tab-index">
            <template v-for="item in tabs">
              <v-tab v-if="item.data.length > 0" :key="item.name">
                <v-badge color="success" :content="item.data.length" inline>
                  <v-icon class="mr-2">{{ item.icon }}</v-icon>
                  {{ item.name }}
                </v-badge>
              </v-tab>
            </template>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <template v-for="(item, i) in tabs">
              <v-tab-item class="mt-2" v-if="item.data.length > 0" :key="i">
                <v-card-title>
                  {{ item.name }}
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="item.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :loading="item.loading"
                  loading-text="Loading... Please wait"
                  :headers="item.columns"
                  :items="item.data"
                  :search="item.search"
                  dense
                >
                  <template v-slot:[`item.po_no_`]="{ item }" class="text-truncate">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <a href="javascript:;" v-bind="attrs" v-on="on" class="d-inline-block text-truncate" style="max-width: 250px" @click="$refs.refPOInfo.getData(item.po_no_)">
                          {{ item.po_no_ }}
                        </a>
                      </template>
                      <span>{{ item.po_no_ }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.contract_no_`]="{ item }" class="text-truncate">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <a href="javascript:;" v-bind="attrs" v-on="on" class="d-inline-block text-truncate" style="max-width: 250px" @click="$refs.refContractInfo.getData(item.contract_no_)">
                          {{ item.contract_no_ }}
                        </a>
                      </template>
                      <span>{{ item.contract_no_ }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.customer_name`]="{ item }" class="text-truncate">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="d-inline-block text-truncate" style="max-width: 250px">
                          {{ item.customer_name }}
                        </span>
                      </template>
                      <span>{{ item.customer_name }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.campaign_description`]="{ item }" class="text-truncate">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <a href="javascript:;" v-bind="attrs" v-on="on" class="d-inline-block text-truncate" style="max-width: 250px" @click="$refs.refCampaignInfo.getData(item.campaign_no_)">
                          {{ item.campaign_description }}
                        </a>
                      </template>
                      <span>{{ item.campaign_description }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.line_order_amount`]="{ item }">
                    {{ numberFormat(item.line_order_amount, 2) }}
                  </template>
                </v-data-table>
              </v-tab-item>
            </template>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>

    <ContractInfo ref="refContractInfo"></ContractInfo>
    <POInfo ref="refPOInfo"></POInfo>
    <CampaignInfo ref="refCampaignInfo"></CampaignInfo>
  </div>
</template>

<script>
import { mdiShopping, mdiViewList } from '@mdi/js'

import ContractInfo from '@/components/rigthbar/ContractInfo.vue'
import POInfo from '@/components/rigthbar/POInfo.vue'
import CampaignInfo from '@/components/rigthbar/CampaignInfo.vue'

export default {
  components: {
    ContractInfo,
    POInfo,
    CampaignInfo,
  },
  data: () => ({
    showLoadingComponent: null,
    showLoadingDetail: null,
    search: '',
    totalContract: 0,
    totalPO: 0,
    selectedData: {},
    tabs: [
      {
        name: 'Your Contract',
        icon: mdiViewList,
        search: null,
        loading: null,
        columns: [
          { text: 'Contract No.', value: 'contract_no_' },
          { text: 'Customer', value: 'customer_name' },
          { text: 'Project', value: 'campaign_description' },
        ],
        data: [],
      },
      {
        name: 'Your PO',
        icon: mdiShopping,
        search: null,
        loading: null,
        columns: [
          { text: 'PO No.', value: 'po_no_' },
          { text: 'Contract No.', value: 'contract_no_' },
          { text: 'Customer', value: 'customer_name' },
          { text: 'Project', value: 'campaign_description' },
        ],
        data: [],
      },
    ],
    tab: null,
    loadingCard: null,
  }),
  methods: {
    async getData() {
      this.showLoadingComponent = true

      await this.$axios
        .get('orders/customerorders', {
          cacheConfig: true,
        })
        .then(res => {
          if (res.status) {
            var resData = res.data
            if (resData.status == 'success') {
              this.datatableData = resData.data.customer_orders
              this.totalContract = resData.data.total_contract
              this.totalPO = resData.data.total_po
            } else {
              this.showAlert(resData.status, resData.message)
            }
          }
          this.showLoadingComponent = false
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          // console.log(error)
          this.$awn.alert('Internal Server Error')
          this.showLoadingComponent = false
        })
    },
    async getDetail(contract_no_, po_no_) {
      this.$refs.myRightbar.show()

      this.showLoadingDetail = true
      this.selectedData = {}

      var data = {
        po_no_: po_no_,
        contract_no_: contract_no_,
      }
      await this.$axios
        .get('orders/customerordersdetail/', {
          params: data,
          cacheConfig: true,
        })
        .then(res => {
          if (res.status) {
            var resData = res.data
            if (resData.status == 'success') {
              this.selectedData = resData.data.po_data
            }

            if (resData.status == 'error') {
              this.$refs.myRightbar.hide()
              this.$awn.alert(resData.message)
              this.showLoadingDetail = false
            }
          }
          this.showLoadingDetail = false
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.$awn.alert('Internal Server Error')
          this.showLoadingDetail = false
        })

      return po_no_
    },
    getTotalContract() {
      return this.totalContract
    },
    getTotalPO() {
      return this.totalPO
    },
    async getContractList() {
      try {
        this.loadingCard = true
        this.tabs[0].loading = true
        this.tabs[0].data = []
        await this.$axios
          .get('orders/contract-list', {
            cacheConfig: true,
          })
          .then(res => {
            if (res.status) {
              var resData = res.data
              if (resData.status == 'success') {
                this.tabs[0].data = resData.data.contract_list
              } else {
                this.showAlert(resData.status, resData.message)
              }
            }
            this.tabs[0].loading = false
            this.loadingCard = false
          })
          .catch(error => {
            this.showAlert('error', 'Internal Server Error : ' + error)
            this.tabs[0].loading = false
            this.loadingCard = false
          })
      } catch (error) {
        this.tabs[0].data = []
        this.tabs[0].loading = false
        this.loadingCard = false
        this.showAlert('error', 'Internal server error : ' + error)
      }
    },
    async getPOList() {
      try {
        this.loadingCard = true
        this.tabs[1].loading = true
        this.tabs[1].data = []
        await this.$axios
          .get('orders/po-list', {
            cacheConfig: true,
          })
          .then(res => {
            if (res.status) {
              var resData = res.data
              if (resData.status == 'success') {
                this.tabs[1].data = resData.data.po_list
              } else {
                this.showAlert(resData.status, resData.message)
              }
            }
            this.tabs[1].loading = false
            this.loadingCard = false
          })
          .catch(error => {
            this.showAlert('error', 'Internal Server Error : ' + error)
            this.tabs[1].loading = false
            this.loadingCard = false
          })
      } catch (error) {
        this.tabs[1].data = []
        this.tabs[1].loading = false
        this.loadingCard = false
        this.showAlert('error', 'Internal server error : ' + error)
      }
    },
  },

  beforeMount() {
    // this.getData()
  },

  mounted() {
    this.getContractList()
    this.getPOList()
  },
}
</script>

<style>
table.font-size-sm tbody td {
  font-size: 12px !important;
}
.lbl {
  color: #bdbdbd !important;
}
.vl {
  color: #616161 !important;
}
</style>